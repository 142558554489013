import { app, getDBInstance, ObjectId, branchApp } from "./dbServices";

export const addCategory = async ({ data, userType }: any) => {
  if (!data) {
    return;
  }

  const selectedApp = userType === "branch" ? branchApp : app;
  const result = await getDBInstance(userType)
    .collection("categories")
    .insertOne({
      name: data.name,
      description: data.description || "",
      image: data.image || null,
      metaData: {
        createdAt: new Date(),
        createdBy: data.createdBy || selectedApp.currentUser.id,
        modifiedAt: null,
        modifiedBy: null,
        isActive: data.isActive !== undefined ? data.isActive : true,
      },
    });
  return result;
};

export const updateCategory = async ({ id, data, userType }: any) => {
  if (!id || !data) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const selectedApp = userType === "branch" ? branchApp : app;

  const result = await getDBInstance(userType)
    .collection("categories")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          name: data.name,
          description: data.description || "",
          image: data.image || null,
          metaData: {
            createdAt: new Date(),
            createdBy: data.createdBy || selectedApp.currentUser.id,
            modifiedAt: new Date(),
            modifiedBy: data.modifiedBy || selectedApp.currentUser.id,
            isActive: data.isActive !== undefined ? data.isActive : true,
          },
        },
      }
    );
  return result;
};

export const deleteCategory = async ({ id, userType }: any) => {
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const category = await getDBInstance(userType)
    .collection("categories")
    .findOne({ _id: id });

  if (category?.image?.source === "cloudflare") {
    const cloudflareImageId = category.image?.file?.id;
    if (cloudflareImageId) {
      try {
        const res = await app.currentUser.callFunction(
          "deleteCloudflareImage",
          {
            imageId: cloudflareImageId,
          }
        );

        console.log("Cloudflare image deletion response:", res);
      } catch (cloudflareError) {
        console.error("Failed to delete Cloudflare image:", cloudflareError);
      }
    } else {
      console.warn("Cloudflare image ID is missing in the category.");
    }
  }

  const result: any = await getDBInstance(userType)
    .collection("categories")
    .deleteOne({
      _id: id,
    });

  return result;
};

export const getCategories = async ({
  page = 0,
  rowsPerPage = 1000,
  userType,
}: any) => {
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance(userType)
    .collection("categories")
    .count({});

  const result = await getDBInstance(userType)
    .collection("categories")
    .aggregate([{ $skip: skip }, { $limit: limit }]);

  return { data: result, totalDocumentCount };
};

export const getOneCategory = async ({ id, userType }: any) => {
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType)
    .collection("categories")
    .findOne({
      _id: id,
    });

  return result;
};

export const UpdateOneCategoryStatus = async ({
  id,
  isActive,
  userType,
}: any) => {
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType)
    .collection("categories")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          "metaData.isActive": isActive,
        },
      }
    );
  return result;
};
