/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { AlertContext } from "controller/context/alertContext";
import { useNavigate } from "react-router-dom";
import envConfig from "../../../env/env.json";
import * as Yup from "yup";
import {
  useRestaurantValues,
  useOneRestaurant,
  useCuisinesList,
  useCategoriesList,
} from "model/queryCalls/queryCalls";
import {
  AddRestaurantValues,
  deleteRestaurant,
  updateRestaurant,
  UpdateOneRestaurantStatus,
} from "controller/services/restaurantServices";
import { FormValues, Location, Address } from "./restaurant.interface";
import { fetchedOptions } from "./dummyData";
import { UserContext } from "controller/context/userContext";
import { useImageUpload } from "components/ ImageUploadUtility";

export const useRestaurant = (handleNext: any) => {
  const { setAlert } = useContext(AlertContext);
  const { userType } = useContext(UserContext);
  const [restaurantId, setRestaurantId] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );

  const [address, setAddress] = useState<Address | null>(null);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [branchId, setBranchId] = useState<string | null>(null);
  const [, setIsSuccessful] = useState<boolean>(false);
  const [location, setLocation] = useState<Location | null>(null);

  const {
    handleFileUpload: handleLogoUpload,
    isUploading: isLogoUploading,
    imageInputRef: logoInputRef,
    onSuccess: onLogoSuccess,
    onError: onLogoError,
    onUploadProgress: onLogoUploadProgress,
    handleDeleteImage: handleDeleteLogoFile,
    genericImage: brandImage,
    setGenericImage: setBrandImage,
  } = useImageUpload({
    uploadPath: "restaurants/logos",
  });

  const {
    handleFileUpload: handleBannerUpload,
    isUploading: isBannerUploading,
    imageInputRef: bannerInputRef,
    onSuccess: onBannerSuccess,
    onError: onBannerError,
    onUploadProgress: onBannerUploadProgress,
    handleDeleteImage: handleDeleteBannerFile,
    genericImage: bannerImage,
    setGenericImage: setBannerImage,
  } = useImageUpload({
    uploadPath: "restaurants/banners",
  });

  const navigate = useNavigate();

  const [pinCode, setPinCode] = useState<string>("");
  const [mapCenterLocation, setMapCenterLocation] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 0,
    lng: 0,
  });
  const [isMapVisible, setIsMapVisible] = useState<boolean>(false);

  const { data: categoriesData } = useCategoriesList({});
  const { data: cuisinesData } = useCuisinesList({});

  const categoryOptions = categoriesData?.data?.map((category: any) => ({
    value: category._id?.toString() || "undefined-id",
    label: category.name || "Unnamed Category",
  }));

  const cuisineOptions = cuisinesData?.data?.map((cuisine: any) => ({
    value: cuisine._id.toString(),
    label: cuisine.name,
  }));

  const { data: oneRestaurantData, refetch: restaurantRefetch } =
    useOneRestaurant({ id: restaurantId });

  useEffect(() => {
    if (restaurantId) {
      restaurantRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId]);

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: "",
    description: "",
    cancellationHours: 0,
    cancellationFee: 0,
    type: "",
    isActive: true,
    contactDetails: "",
    username: "",
    password: "",
    paymentStatus: "",
    category: [],
    cuisine: [],
    orderType: [],
    restaurantIcon: null,
    restaurantBanner: null,
    workingHours: {
      sunday: { startTime: "", endTime: "" },
      monday: { startTime: "", endTime: "" },
      tuesday: { startTime: "", endTime: "" },
      wednesday: { startTime: "", endTime: "" },
      thursday: { startTime: "", endTime: "" },
      friday: { startTime: "", endTime: "" },
      saturday: { startTime: "", endTime: "" },
    },
    tax: 0,
    deliveryCharge: 0,
    platformFee: 0,
  });

  const getCoordinatesFromPinCode = async (pinCode: string) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${pinCode}&key=${envConfig.MapsAPIKey}`
      );
      const data = await response.json();

      if (data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setMapCenterLocation({ lat: location.lat, lng: location.lng });
        setLocation({
          type: "Point",
          coordinates: [location.lng, location.lat],
        });
        setAddress({
          address: data.results[0].formatted_address,
          location: {
            type: "Point",
            coordinates: [location.lng, location.lat],
          },
        });
        setIsMapVisible(true);
      } else {
        setIsMapVisible(true);
        setAlert({
          active: true,
          type: "error",
          message: "Invalid pin code. Please enter a valid one.",
        });
      }
    } catch (error) {
      console.error("Error fetching coordinates from pin code:", error);
      setIsMapVisible(false);
      setAlert({
        active: true,
        type: "error",
        message: "Error fetching location. Please try again later.",
      });
    }
  };

  const getAddress = async (lat: number, lng: number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${envConfig.MapsAPIKey}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        setAddress({
          address: data.results[0].formatted_address,
          location: {
            type: "Point",
            coordinates: [lng, lat],
          },
        });
      } else {
        setAddress({
          address: "Address not found",
          location: {
            type: "Point",
            coordinates: [lng, lat],
          },
        });
      }
    } catch (error) {
      console.error("Error fetching address from coordinates:", error);
    }
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat() || 0;
    const lng = event.latLng?.lng() || 0;

    setMapCenterLocation({ lat, lng });

    setLocation({
      type: "Point",
      coordinates: [lng, lat],
    });

    getAddress(lat, lng);
  };

  const handlePinCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPinCode(e.target.value);
  };

  const handlePinCodeBlur = () => {
    if (pinCode) {
      getCoordinatesFromPinCode(pinCode);
    }
  };

  const handlePinCodeKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      getCoordinatesFromPinCode(pinCode);
    }
  };
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const paymentStatusOptions = [
    { value: "Paid", label: "Paid" },
    { value: "Unpaid", label: "Unpaid" },
  ];
  const mapIdsToOptions = (
    ids: string[],
    options: { value: string; label: string }[]
  ) => {
    return ids
      .filter((id) => id !== null && id !== undefined)
      .map((id) => options?.find((option) => option.value === id.toString()))
      .filter(Boolean);
  };

  // Helper function to ensure time is in 24-hour format
  const formatTo24Hour = (time: string) => {
    if (!time) return "";

    // If time includes AM/PM
    if (
      time.toLowerCase().includes("am") ||
      time.toLowerCase().includes("pm")
    ) {
      const [timeStr, period] = time.toLowerCase().split(" ");
      let [hours, minutes] = timeStr.split(":");
      let hour = parseInt(hours, 10);

      // Convert 12-hour format to 24-hour format
      if (period === "pm" && hour !== 12) {
        hour += 12;
      } else if (period === "am" && hour === 12) {
        hour = 0;
      }

      return `${hour.toString().padStart(2, "0")}:${minutes}`;
    }

    // If time is already in 24-hour format
    const [hours, minutes] = time.split(":");
    return `${hours.padStart(2, "0")}:${minutes}`;
  };

  useEffect(() => {
    if (!restaurantId || !oneRestaurantData) return;

    setBranchId(oneRestaurantData?.branchDetails[0]?._id);

    if (oneRestaurantData?.branchDetails[0]?.address?.location) {
      const branchLocation =
        oneRestaurantData.branchDetails[0].address.location;
      setLocation({
        type: "Point",
        coordinates: branchLocation.coordinates,
      });

      setMapCenterLocation({
        lat: branchLocation.coordinates[1],
        lng: branchLocation.coordinates[0],
      });
    }

    if (oneRestaurantData?.branchDetails[0]?.logo) {
      setBrandImage(
        (prevImage) => prevImage || oneRestaurantData?.branchDetails[0]?.logo
      );
    }

    if (oneRestaurantData?.branchDetails[0]?.banner) {
      setBannerImage(
        (prevBanner) =>
          prevBanner || oneRestaurantData?.branchDetails[0]?.banner
      );
    }

    if (oneRestaurantData?.branchDetails[0]?.address) {
      setAddress(oneRestaurantData.branchDetails[0].address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId, oneRestaurantData]);

  // Separate effect for form values that depend on all options
  useEffect(() => {
    if (
      !restaurantId ||
      !oneRestaurantData ||
      !categoryOptions ||
      !cuisineOptions
    )
      return;

    setInitialValues((prevValues: any) => {
      if (prevValues.name === oneRestaurantData?.name?.en) {
        return prevValues;
      }

      const mappedCategories = mapIdsToOptions(
        oneRestaurantData.branchDetails[0]?.category,
        categoryOptions
      );
      const mappedCuisines = mapIdsToOptions(
        oneRestaurantData.branchDetails[0]?.cuisine,
        cuisineOptions
      );

      const workingHoursObject = daysOfWeek.reduce((acc, day) => {
        const dayData = oneRestaurantData?.branchDetails[0]?.workingHours?.find(
          (wh: any) => wh.name.toLowerCase() === day.toLowerCase()
        );

        const startTime = dayData?.startTime
          ? formatTo24Hour(dayData.startTime)
          : "";
        const endTime = dayData?.endTime ? formatTo24Hour(dayData.endTime) : "";

        acc[day.toLowerCase()] = {
          startTime,
          endTime,
        };
        return acc;
      }, {} as Record<string, { startTime: string; endTime: string }>);

      return {
        name: oneRestaurantData?.name?.en || "",
        description: oneRestaurantData?.description || "",
        type: oneRestaurantData?.branchDetails[0]?.type || "",
        cancellationHours:
          Number(oneRestaurantData?.branchDetails[0]?.cancellationHours) || 0,
        cancellationFee:
          Number(oneRestaurantData?.branchDetails[0]?.cancellationFee) || 0,
        tax: Number(oneRestaurantData?.branchDetails[0]?.tax) || 0,
        deliveryCharge:
          Number(oneRestaurantData?.branchDetails[0]?.deliveryCharge) || 0,
        platformFee:
          Number(oneRestaurantData?.branchDetails[0]?.platformFee) || 0,
        isActive: oneRestaurantData?.isActive || true,
        location: oneRestaurantData?.branchDetails[0]?.address?.location
          ?.coordinates || {
          lat: 0,
          lng: 0,
        },
        contactDetails:
          oneRestaurantData?.branchDetails[0]?.contactDetails || "",
        username: oneRestaurantData?.branchDetails[0]?.username || "",
        password: oneRestaurantData?.branchDetails[0]?.password || "",
        paymentStatus: oneRestaurantData?.branchDetails[0]?.paymentStatus || "",
        cuisine: mappedCuisines.map((cuisId) => cuisId.value),
        category: mappedCategories.map((catId) => catId.value),
        orderType: oneRestaurantData?.branchDetails[0]?.orderType || [],
        restaurantIcon: oneRestaurantData?.branchDetails[0]?.logo || null,
        restaurantBanner: oneRestaurantData?.branchDetails[0]?.banner || null,
        workingHours: workingHoursObject || {
          sunday: { startTime: "", endTime: "" },
          monday: { startTime: "", endTime: "" },
          tuesday: { startTime: "", endTime: "" },
          wednesday: { startTime: "", endTime: "" },
          thursday: { startTime: "", endTime: "" },
          friday: { startTime: "", endTime: "" },
          saturday: { startTime: "", endTime: "" },
        },
      };
    });
  }, [restaurantId, oneRestaurantData, categoryOptions, cuisineOptions]);

  useEffect(() => {
    const fetchOptions = () => {
      setOptions(fetchedOptions);
    };
    fetchOptions();
  }, []);

  const {
    data: restaurantData,
    status,
    refetch,
  } = useRestaurantValues({
    page,
    rowsPerPage,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setRowsPerPage(+event.target.value);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your restaurant name"),
    description: Yup.string().required("Please enter a description"),
    type: Yup.string().required("Please select your restaurant type"),
    contactDetails: Yup.string().required("Please enter your contact details"),
    username: Yup.string()
      .email("Please enter a valid email address")
      .required("Please enter a valid email"),
    password: Yup.string().required("Please enter a password"),
    paymentStatus: Yup.string().required("Please select a payment status"),
    cuisine: Yup.array().of(Yup.string()).required("Please select a cuisine"),
    category: Yup.array().of(Yup.string()).required("Please select a category"),
    orderType: Yup.array()
      .of(Yup.string())
      .required("Please select at least one order type"),
  });

  const transformWorkingHours = (workingHours: any) => {
    return daysOfWeek.map((day) => {
      const dayKey = day.toLowerCase();
      // Ensure both start and end times are in 24-hour format
      const startTime = workingHours[dayKey]?.startTime
        ? formatTo24Hour(workingHours[dayKey].startTime)
        : "";
      const endTime = workingHours[dayKey]?.endTime
        ? formatTo24Hour(workingHours[dayKey].endTime)
        : "";

      return {
        name: day,
        status: !!(startTime && endTime),
        startTime,
        endTime,
      };
    });
  };

  const onSubmit = async (values: FormValues) => {
    const transformedWorkingHours = transformWorkingHours(values.workingHours);
    setLoading(true);

    try {
      const restaurantData = {
        ...values,
        cancellationHours: Number(values.cancellationHours) || 0,
        cancellationFee: Number(values.cancellationFee) || 0,
        tax: Number(values.tax) || 0,
        deliveryCharge: Number(values.deliveryCharge) || 0,
        platformFee: Number(values.platformFee) || 0,
        workingHours: transformedWorkingHours,
        logo: brandImage,
        banner: bannerImage,
      };

      const locationCoordinates = location
        ? {
            lng: location.coordinates[0],
            lat: location.coordinates[1],
          }
        : null;

      if (restaurantId) {
        const updateRes = await updateRestaurant({
          restaurantId,
          data: restaurantData,
          location: locationCoordinates,
          address: address?.address || "",
          userType: userType,
        });
        if (updateRes) {
          setBranchId(updateRes.branchId);

          // Refetch all necessary data
          await Promise.all([
            refetch(), // Refetch restaurant list
            restaurantRefetch(), // Refetch current restaurant data
          ]);

          setAlert({
            active: true,
            type: "success",
            message: "Restaurant updated successfully",
          });
          setIsSuccessful(true);
          handleNext();
        }
      } else {
        const result = await AddRestaurantValues({
          data: restaurantData,
          location: locationCoordinates,
          address: address?.address || "",
          userType: userType,
        });
        if (result) {
          setBranchId(result.insertedId);

          // Refetch restaurant list after adding new restaurant
          await refetch();

          setAlert({
            active: true,
            type: "success",
            message: "Restaurant created successfully",
          });
          setIsSuccessful(true);
          handleNext();
        }
      }
    } catch (error: any) {
      setAlert({
        active: true,
        type: "error",
        message: error.message || "Failed to submit. Please try again.",
      });
      setIsSuccessful(false);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (restaurantId: string) => {
    try {
      const result = await deleteRestaurant(restaurantId, userType);
      if (result?.restaurantDeleteResult?.deletedCount > 0) {
        setAlert({
          active: true,
          type: "success",
          message: "Restaurant deleted successfully",
        });
        refetch();
      } else {
        throw new Error("Failed to delete the restaurant");
      }
    } catch (error) {
      console.error("Delete restaurant error:", error);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete restaurant. Please try again.",
      });
    }
  };

  const onToggleActive = async (restaurantId: string, newIsActive: boolean) => {
    try {
      await UpdateOneRestaurantStatus({
        id: restaurantId,
        isActive: !newIsActive,
        userType: userType,
      });
      refetch();

      setAlert({
        active: true,
        type: "success",
        message: "Restaurant status updated successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to update restaurant status",
      });
    }
  };

  return {
    page,
    rowsPerPage,
    navigate,
    userType,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    loading,
    setLoading,
    initialValues,
    validationSchema,
    onSubmit,
    options,
    isActive,
    setIsActive,
    refetch,
    location,
    setLocation,
    branchId,
    restaurantData,
    status,
    setAddress,
    address,
    restaurantId,
    setRestaurantId,
    oneRestaurantData,
    onToggleActive,
    categoryOptions,
    cuisineOptions,
    mapIdsToOptions,
    mapCenterLocation,
    isMapVisible,
    handleMapClick,
    handlePinCodeChange,
    handlePinCodeBlur,
    handlePinCodeKeyDown,
    daysOfWeek,
    pinCode,
    paymentStatusOptions,

    handleBannerUpload,
    isBannerUploading,
    bannerInputRef,
    onBannerSuccess,
    onBannerError,
    onBannerUploadProgress,
    handleDeleteBannerFile,
    bannerImage,

    handleLogoUpload,
    isLogoUploading,
    logoInputRef,
    onLogoSuccess,
    onLogoError,
    onLogoUploadProgress,
    handleDeleteLogoFile,
    brandImage,
  };
};
